<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 提示信息 -->
            <alert-info :info="infodata"></alert-info>
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">遗产要素单体或局部的测绘图信息</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.saveJson"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="数据密级" prop="sjmj">
                    <el-select
                        v-model="formdata.saveJson.sjmj"
                        placeholder="请选择数据密级"
                        :disabled="!isEdit"
                        @change="changeSJMJ"
                    >
                        <el-option label="公开" value="公开"></el-option>
                        <el-option label="不公开" value="不公开"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-show="formdata.saveJson.sjmj === '公开'"
                    label="发布范围"
                    prop="fbfw"
                >
                    <el-select
                        v-model="formdata.saveJson.fbfw"
                        placeholder="请选择发布范围"
                        :disabled="!isEdit"
                    >
                        <el-option
                            label="互联网公开"
                            value="互联网公开"
                        ></el-option>
                        <el-option
                            label="行业内部公开"
                            value="行业内部公开"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="遗产要素" prop="ycysbm">
                    <el-select
                        v-model="formdata.saveJson.ycysbm"
                        filterable
                        clearable
                        placeholder="请选择遗产要素"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in ycysList"
                            :key="index"
                            :label="item.Mc"
                            :value="item.Bm"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="名称" prop="pmhlmmc">
                    <el-tooltip
                        effect="light"
                        content="立面名称，例如 正立面、一层平面图"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.pmhlmmc"
                            onkeydown="if(event.keyCode==32) return false"
                            placeholder="请输入名称"
                            :disabled="!isEdit"
                        ></el-input>
                    </el-tooltip>
                </el-form-item>
                <!-- 示意图 图纸上传 -->
                <basic-upload
                    v-if="formdata.saveJson.sjmj === '公开'"
                    ref="uploadFile"
                    :itemId="itemId"
                    :isEdit="isEdit"
                    :metaData.sync="formdata.metaJson"
                    :basicType="basicType"
                    :onlyOne="true"
                >
                </basic-upload>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from "../0_com_js/edit-button.js";
import infoMixin from "../0_com_js/info_mixin.js";
import { mapActions } from "vuex";
export default {
    name: "hem_part_reference_drawing",
    components: {},
    mixins: [infoMixin],
    data() {
        return {
            snButton: snButton,
            rules: {
                sjmj: [
                    {
                        required: true,
                        message: "请选择数据密级",
                        trigger: "blur",
                    },
                ],
                fbfw: [
                    {
                        required: true,
                        message: "请选择发布范围",
                        trigger: "blur",
                    },
                ],
                ycysbm: [
                    {
                        required: true,
                        message: "请选择遗产要素",
                        trigger: "change",
                    },
                ],
                pmhlmmc: [
                    { required: true, message: "请输入名称", trigger: "blur" },
                ],
            },
            formdata: {
                saveJson: {
                    sjmj: "公开",
                    fbfw: "行业内部公开",
                    shzt: 1,
                    ycysbm: "",
                    pmhlmmc: "",
                },
                metaJson: [],
                heritageId: "",
                fileID: "",
                originalFileID: "",
                itemID: "13",
                userName: "",
                userId: "",
            },
            ycysList: [],
        };
    },
    computed: {},
    mounted() {
        this.getYcys();
    },
    methods: {
        ...mapActions(["getYcysList"]),
        async getYcys() {
            let res = await this.getYcysList({
                ycdbm: this.heritageId,
            });
            this.ycysList = res.ResultValue;
        },
    },
};
</script>
<style lang="scss" scoped></style>
